export const usernameMatch = [
    {
        username :'backyard-arena-turf'
    },
    // {
    //     username :'b3-turf'
    // }
];



export const dayMapping: any = {
    D1: 'Monday',
    D2: 'Tuesday',
    D3: 'Wednesday',
    D4: 'Thursday',
    D5: 'Friday',
    D6: 'Saturday',
    D7: 'Sunday',
  };

 export const dayOrder: Record<string, number> = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 7,
  };
