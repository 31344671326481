import {
  Dispatch,
  isValidElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Button, Col, List, Modal, Row } from "antd";
import { useRecoilState } from "recoil";
import "./index.scss";
import Tile from "./Tile";
import { TileCardDetails } from "../../Interfaces";
import  turfData  from "../../../config/turf-config.json";
import {
  DISTANCE_RANGE_MAX,
  FILTERS_KEYS,
  SIZE_RANGE,
  SORT_KEYS,
} from "../../../components/FilterAndSort/constants/constants";
import FilterSort from "../../../components/FilterAndSort";
import { useMediaQuery } from "react-responsive";
import MobileCard from "../../../components/Cards/MobileCard";
import {
  calculateDistance,
  sortTurfByLocation,
} from "../../../utils/calculateDistance";
import SearchBar from "./Search";
import HelmetComponent from "../../../components/Helmet/HelmetComponent";
import { Carousel } from "react-responsive-carousel";
import MobileLocationSelector from "../../../components/Location/MobileLocation";
import { useUserLocation } from "../../../components/Location/LocationContext";
import getUserLocation from "../../../utils/location";
import SelectLocation from "../../../components/SelectLocation";
import { useLocation, useNavigate } from "react-router-dom";
import constants from "../../../utils/constants";
import { filtersState } from "./filters/filtersState";
import FiltersInitializer from "../../../components/FilterAndSort/FiltersInitializer";
import { WhatsAppOutlined, RightOutlined, ArrowRightOutlined } from "@ant-design/icons";

const gridConfig = {
  gutter: 16,
  xs: 1,
  sm: 2,
  md: 2,
  lg: 3,
  xl: 3,
  xxl: 3,
};

enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

interface TileContainerProp {
  city: string;
  search: string;
  setCity: Dispatch<React.SetStateAction<string>>;
  turfData:any[]
}

const whatsappNumber = "+919238764656"; // Replace with your WhatsApp number in international format
const whatsappUrl = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=Hello, I have a query regarding the turf. Could you please provide more information about availability and timings?`;

const TileContainer = ({ city, search, setCity }: TileContainerProp) => {
  const [filters, setFilters] = useRecoilState(filtersState);

  const navigate = useNavigate();
  // const [originalTurfData, setOriginalTurfData] = useState<TileCardDetails[]>(
  //   []
  // );
  const [showNoTurf, setShowNoTurf] = useState(false);
  const [filteredTurfData, setFilteredTurfData] = useState<TileCardDetails[]>(
    []
  );

  const [visible, setVisible] = useState(false);
  const { userLocation, setUserLocation } = useUserLocation();

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const sortExclusiveTurf = (turfData: TileCardDetails[]) => {
    const sortedExclusiveTurf: TileCardDetails[] = [];
    const exclusiveTurfData = turfData.filter(
      ({ data }) => data.exclusivePriority
    );
    // Iterate through special turf IDs and find corresponding items
    exclusiveTurfData.forEach(({ data, name }) => {
      const exclusiveTurfIndex = turfData.findIndex(
        (item) => item.name === name
      );
      if (exclusiveTurfIndex !== -1) {
        // If special turf item exists, remove it from sorted array and add to specialTurfs
        const specialTurf = turfData.splice(exclusiveTurfIndex, 1)[0];
        // If priority is defined, add it to the special turf object
        if (data.exclusivePriority !== undefined) {
          specialTurf.data.exclusivePriority = data.exclusivePriority;
        }
        sortedExclusiveTurf.push(specialTurf);
      }
    });

    // Sort special turfs based on priority
    sortedExclusiveTurf.sort(
      (a, b) =>
        (a.data.exclusivePriority ?? 0) - (b.data.exclusivePriority ?? 0)
    );

    // Place special turf items at the beginning of the sorted array
    turfData.unshift(...sortedExclusiveTurf);
    return turfData;
  };

  useEffect(() => {
    // Initialize the original data
    // setOriginalTurfData(turfData);
    const localStorageCity = localStorage.getItem("selectedCity");
    if (!localStorageCity) {
      setVisible(true);
    }

    if (!userLocation) {
      getUserLocation(setUserLocation);
    }
  }, []);

  useEffect(() => {
    if (!!userLocation) {
      setVisible(false);
      setFilters((prev) => ({
        ...prev,
        // sortBy: SORT_KEYS.DISTANCE_NEAREST,
      }));
    }
  }, [userLocation]);

  useEffect(() => {
    // Filter the original data based on city, search query, and filters
    const filteredCityData = turfData.filter(
      (item) => item.data.city.toLowerCase() === city.toLowerCase()
    );
    if (!filteredCityData.length) {
      navigate("/noData");
    }
    const filteredData = filteredCityData.filter((item) =>
      item.name.toLowerCase().includes(search.toLowerCase())
    );

    const sortedData = sort(filters, filteredData);
    const filteredAndSortedData = filter(filters.filters ?? [], sortedData);
    const filterSizeRage = filterSizeRange(
      filters.sizeRange ?? [],
      filteredAndSortedData
    );

    const filterDistanceRange = filterTurfsByDistanceRange(
      filters.distanceRange ?? DISTANCE_RANGE_MAX,
      filterSizeRage
    );
    const sortExclusiveTurfData = sortExclusiveTurf(filterDistanceRange);

    if (filterSizeRage.length === 0) setShowNoTurf(true);
    else setShowNoTurf(false);

    setFilteredTurfData(sortExclusiveTurfData);
  }, [city, search, filters]);

  function comparePrice(
    a: TileCardDetails,
    b: TileCardDetails,
    sortOrder: SortOrder
  ): number {
    // Helper function to get the minimum price from the areaPrice array
    const getMinPrice = (turf: TileCardDetails): number => {
      let minPrice = Infinity;
      turf.data.areaPrice.forEach((area) => {
        const minAreaPrice = Math.min(
          area.price.weekday.regularHours,
          area.price.weekday.regularHours
          // area.price.weekday?.happyHours?.price || 0,
          // area.price.weekend?.happyHours?.price || 0
        );
        if (minAreaPrice < minPrice) {
          minPrice = minAreaPrice;
        }
      });
      return minPrice;
    };

    const priceA = getMinPrice(a);
    const priceB = getMinPrice(b);

    if (sortOrder === SortOrder.ASC) {
      return priceA - priceB;
    } else {
      return priceB - priceA;
    }
  }
  function compareHeight(
    a: TileCardDetails,
    b: TileCardDetails,
    sortOrder: SortOrder
  ): number {
    // Helper function to get the maximum height from the areaPrice array
    const getMaxHeight = (turf: TileCardDetails): number => {
      let maxHeight = -1; 
      turf.data.areaPrice.forEach((area:any) => {
        const height = area?.pitchDimensions?.height; // Accessing the height
        if (height > maxHeight) {
          maxHeight = height;
        }
      });
      // console.log("turfData", turfData);
      return maxHeight;
    };
    const heightA = getMaxHeight(a);
    const heightB = getMaxHeight(b);
    // Sort in descending order
    if (sortOrder === SortOrder.DESC) {
      return heightB - heightA;
    } else {
      return heightA - heightB; // This will keep heights sorted in ascending order if needed
    }
  }  
  function sortByRating(data: TileCardDetails[], sortOrder: SortOrder) {
    if (sortOrder === SortOrder.ASC) {
      return data.sort(
        (a, b) => Number(a.data.starRating) - Number(b.data.starRating)
      );
    } else {
      return data.sort(
        (a, b) => Number(b.data.starRating) - Number(a.data.starRating)
      );
    }
  }

  const sort = (filters: any, turfData = filteredTurfData) => {
    const sortKey = filters.sortBy;

    switch (sortKey) {
      case SORT_KEYS.PRICE_HIGH_TO_LOW:
        return turfData.sort((a, b) => comparePrice(a, b, SortOrder.DESC));
      case SORT_KEYS.RATING_HIGH_TO_LOW:
        return sortByRating(turfData, SortOrder.DESC);
      case SORT_KEYS.RATING_LOW_TO_HIGH:
        return sortByRating(turfData, SortOrder.ASC);
      case SORT_KEYS.DISTANCE_NEAREST:
        return sortTurfByLocation(turfData, userLocation);
      case SORT_KEYS.TURF_HEIGHT:
        return turfData.sort((a, b) => compareHeight(a, b, SortOrder.DESC));
      default:
        return turfData.sort((a, b) => comparePrice(a, b, SortOrder.ASC));
    }
  };

  const filter = (filters: string[], turfData: TileCardDetails[]) => {
    let filteredData = [...turfData];

    filters?.forEach((filterKey) => {
      switch (filterKey) {
        case FILTERS_KEYS.COVERED:
          filteredData = filteredData.filter(
            ({ data }) => data.isRainProtected === true
          );
          break;

        case FILTERS_KEYS.OPEN_24_7:
          filteredData = filteredData.filter(
            ({ data }) => data.availabilityData?.isAvailable24Hours === true
          );
          break;

        case FILTERS_KEYS.CAFE:
          filteredData = filteredData.filter(
            ({ data }) => data.hasCafe === true
          );
          break;

        case FILTERS_KEYS.PARTNERED:
          filteredData = filteredData.filter(
            ({ data }) => data.partnership === true
          );
          break;
        case FILTERS_KEYS.UNCOVERED:
          filteredData = filteredData.filter(
            ({ data }) => data.isRainProtected === false
          );
          {console.log(filteredData)};
          break;
      }
    });

    return filteredData;
  };

  const filterSizeRange = (range: number[], turfData: TileCardDetails[]) => {
    if (!range.length) return turfData;
    return turfData.filter(({ data }) => {
      // Check if any area within the size range exists in the areaPrice array
      return data.areaPrice.some((areaPrice) => {
        const turfArea = parseInt(areaPrice.area);
        return turfArea >= range[0] && turfArea <= range[1];
      });
    });
  };

  const filterTurfsByDistanceRange = (
    distanceRange: number,
    turfData: TileCardDetails[]
  ) => {
    if (userLocation && distanceRange != DISTANCE_RANGE_MAX) {
      const sortedTurfs = sortTurfByLocation(turfData, userLocation);

      const turfsInRange = sortedTurfs.filter((turf) => {
        const distance = calculateDistance(
          userLocation.latitude,
          userLocation.longitude,
          Number(turf.data.location.latitude),
          Number(turf.data.location.longitude)
        );
        return distance <= distanceRange;
      });
      return turfsInRange;
    } else {
      return turfData;
    }
  };

  // const onSearch = (search: string) => {
  //   setSearch(search);
  // };

  const handleBannerRedirect = (bannerSrc: any) => {
    if (bannerSrc.includes("regional")) {
      window.location.href =
        "https://regional-turfyard.turfbooking.in/?area=8800&price=1300";
    }
    else if (bannerSrc.includes("allRounder")) {
      window.location.href = "https://all-rounder-turf.turfbooking.in/?area=7000&price=1300";
    } else if (bannerSrc.includes("escobar")) {
      window.location.href = "https://the-escobar-turf-arena.turfbooking.in/?area=6500&price=800";
    } else if (bannerSrc.includes("backyardArena")) {
      window.location.href = "https://backyard-arena-turf.turfbooking.in/?area=5500&price=1000";
    } else if (bannerSrc.includes("oneTip")) {
      window.location.href = "https://one-tip-turf.turfbooking.in/?area=8000&price=1200";
    } else if (bannerSrc.includes("greenland")) {
      window.location.href = "http://greenland-turf-and-cafe.turfbooking.in/?area=6000&price=700";
    } else if (bannerSrc.includes("sp")) {
      window.location.href = "http://sp-turf-and-cafe.turfbooking.in/?area=8800&price=1300";
    } else if (bannerSrc.includes("harbour")) {
      window.location.href = "https://the-harbour-house-sports-cafe-and-turf.turfbooking.in/?area=8000&price=1300";
    }else if (bannerSrc.includes("homeground")) {
      window.location.href = "https://the-homeground-turf-and-cafe.turfbooking.in/?area=6500&price=1100";
    }
  };

  function ScrollRestoration() {
    const location = useLocation();

    useEffect(() => {
      const savedPosition = sessionStorage.getItem(location.pathname);
      if (savedPosition) {
        window.scrollTo(0, parseInt(savedPosition, 10));
      }
    }, [location]);

    const handleScroll = useCallback(() => {
      sessionStorage.setItem(location.pathname, window.scrollY.toString());
    }, [location.pathname]);

    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [handleScroll]);

    return null;
  }

  const url = window.location.href;
  const BannerURL = [
    "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/all_banners/Indore/regional-turfyard/desktop/1.png",
    "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/all_banners/Indore/all-rounder-turf/desktop/1.png",
    "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/all_banners/Indore/the-escobar-turf-arena/desktop/1.png",
    "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/all_banners/Indore/backYard-arena-turf/desktop/1.png",
    "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/all_banners/Indore/one-tip-turf/desktop/1.png",
    "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/all_banners/Indore/greenland-turf-and-cafe/desktop/1.png",
    "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/all_banners/Indore/sp-turf-and-cafe/desktop/1.png",
    "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/all_banners/Indore/the-harbour-house-sports-cafe-and-turf/desktop/1.png",
    "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/all_banners/Indore/the-homeground-turf-and-cafe/desktop/1.png",
  ];
  const mobileBannerUrl = [
    "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/all_banners/Indore/regional-turfyard/mobile/1.png",
   "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/all_banners/Indore/all-rounder-turf/mobile/1.png",
   "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/all_banners/Indore/the-escobar-turf-arena/mobile/1.png",
   "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/all_banners/Indore/backYard-arena-turf/mobile/1.png",
   "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/all_banners/Indore/one-tip-turf/mobile/1.png",
   "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/all_banners/Indore/greenland-turf-and-cafe/mobile/1.png",
   "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/all_banners/Indore/sp-turf-and-cafe/mobile/1.png",
   "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/all_banners/Indore/the-harbour-house-sports-cafe-and-turf/mobile/1.png",
   "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/all_banners/Indore/the-homeground-turf-and-cafe/mobile/1.png",
  ];

  return (
    <>
      <SelectLocation
        setCity={setCity}
        visible={visible}
        onClose={() => setVisible(false)}
      />
      <ScrollRestoration />
      <HelmetComponent
        title="Turf Booking - Book Turfs Hassle-Free"
        description="Discover top turfs near you in Indore with turfbooking.in. 
        Book sports & event venues hassle-free. Your go-to for turf reservations | turfbooking.in"
        link={url}
      />
      <FiltersInitializer />
      <div>
        <Row className="container">
          <Col xs={24}>
            {isMobile && <MobileLocationSelector />}
            {city.toLowerCase() === constants.DEFAULT_CITY.toLowerCase() && (
              <div className="carousel">
              <Carousel
                autoPlay={true}
                infiniteLoop={true}
                interval={2000}
                stopOnHover={true}
                showIndicators={false}
                showThumbs={false}
                onClickItem={(index, element) => {
                  if (
                    isValidElement(element) &&
                    element.props &&
                    element.props.src
                  ) {
                    handleBannerRedirect(element.props.src);
                  }
                }}
                showArrows={true}
                showStatus={false}
              >
                {(isMobile ? mobileBannerUrl : BannerURL).map((item, index) => {
                  return (
                    <img
                      src={item}
                      key={index}
                      alt=""
                      title=""
                      width={"100%"}
                      className="offer-banner-img"
                    />
                  );
                })}
              </Carousel>
              </div>
            )}
          </Col>
          <Col xs={24} md={4}>
            <FilterSort setFilters={setFilters} filters={filters} />
            <div className="turf-count">
              <span>{filteredTurfData.length}</span>{" "}
              {filteredTurfData.length === 1 ? "Turf found" : "Turf's found"}
            </div>
          </Col>
          <Col xs={24} md={20}>
            <Row
              style={{
                margin: "0 1rem 3rem 1rem",
              }}
              align={"stretch"}
              gutter={[24, 24]}
              wrap={true}
            >
              {filteredTurfData.map((turfData, i) => {
                return (
                  <Col xl={8} lg={12} md={12} sm={12} xs={24} key={i}>
                    {isMobile ? (
                      <MobileCard {...turfData} city={city} />
                    ) : (
                      <Tile {...turfData} city={city} />
                    )}
                  </Col>
                );
              })}

              {showNoTurf && <div className="no-data">No Turf Found</div>}
            </Row>
          </Col>
        </Row>
        <a
          href={whatsappUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-button"
        >
          <div className="button-content">
            <WhatsAppOutlined className="whatsapp-icon" />
            <span className="whatsapp__text">
              Chat With Us
              <span className="whatsapp-text__icon">
                <ArrowRightOutlined />
              </span>
            </span>
          </div>
        </a>
      </div>
    </>
  );
};

export default TileContainer;
